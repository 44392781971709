.image-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 4px;
}

.tile {
  background-image: url('../images/visionboard1.jpg');
  background-size: cover;
  width: 100%;
  padding-top: 100%; /* Maintain aspect ratio */
}
